import React, { createElement, PureComponent } from 'react'
import { keys, map, noop } from 'lodash'
import cx from 'classnames'
import style from './tabs.module.scss'

export default class Tabs extends PureComponent {
  static defaultProps = {
    onTabChange: noop,
  }

  state = {
    activeIndex: 0,
  }

  tabRefs = {}

  componentDidUpdate = (prevProps, { activeIndex }) => {
    if (activeIndex !== this.state.activeIndex) {
      this.props.onTabChange(this.state.activeIndex)
    }
  }

  setActiveIndex = index =>
    this.setState({ activeIndex: index }, () => this.props.onTabChange(index))

  render() {
    const { className = '', items } = this.props
    const { activeIndex } = this.state
    const names = keys(items)

    return (
      <div className={ cx(style.main, className) }>
        <div className={ style.links}>
        {
          map(names, (name, index) =>
            <div
              key={ name }
              className={ cx(style.link, {
                [style.activeLink]: index === activeIndex,
              }) }
              onClick={ () => this.setActiveIndex(index) }
            >{
              name
            }
            </div>
          )
        }
        </div>
        <div className={ style.contents }>
        {
          map(items, (content, name) =>
            <div key={ name } className={ cx(style.tab, {
              [style.activeTab]: name === names[activeIndex]
            }) }>
            { content }
            </div>
          )
        }
        </div>
      </div>
    )
  }
}

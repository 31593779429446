import React, { Component } from 'react'
import { Profile as Layout } from 'components/layout/Layout'
import { AuthGuard } from 'components/common/Auth'
import Profile from 'components/section/Profile'

export default ({ location }) => (
  <AuthGuard location={ location }>
    <Layout hasHeader location={ location }>
      <Profile />
    </Layout>
  </AuthGuard>
)

import React, { Component } from 'react'
import { isEmpty, flowRight } from 'lodash'
import { Trans, t } from '@lingui/macro'
import Form from 'components/common/Form'
import Tabs from 'components/common/Tabs'
import Button from 'components/common/Button'
import Loading from 'components/common/Loading'
import ErrorMessage from 'components/common/ErrorMessage'
import { withLocale } from 'components/common/Locale'
import { withAuth } from 'components/common/Auth'
import { Avatar, General, UserTypeSpecific } from 'components/section/ProfileFields'
import { Main, Actions, Title, Submit } from 'components/common/Form/Ui'
import { updateProfileImage } from '~/service/user'
import style from './profile.module.scss'

class Profile extends Component {
  state = {
    isEditable: false,
    hasError: false,
  }

  handleSubmit = async data => {
    const { updateUser } = this.props
    this.setState({ isLoading: true })

    try {
      const result = await updateUser(data)
    } catch (e) {
      console.log(e)
      this.setState({ hasError: true })
    }

    this.setState({
      isLoading: false,
      isEditable: false
    })
  }

  handleAvatarSubmit = async picture => {
    const { user, updateUser } = this.props
    try {
      await updateUser({
        picture,
      })
    } catch (e) {
      console.log(e)
      this.setState({ hasError: true })
    }
  }

  handleChange = () => this.setState({ hasError: false })

  setEditable = value => {
    if (value) {
      window.scroll({ top: 0, behavior: 'smooth' })
    }
    this.setState({ isEditable: !!value })
  }

  render() {
    const { i18n, user } = this.props
    const { isLoading, hasError, isEditable } = this.state

    return (
      <Main className={ style.main }>
        {
          isLoading ?
            <Loading isFullScreen /> :
            null
        }
        {
          hasError ?
            <ErrorMessage>
              <Trans>
                Lo sentimos, ha ocurrido un error. Inténtalo de nuevo más tarde.
              </Trans>
            </ErrorMessage> :
            null
        }
        {
          user ?
          <>
            <Form
              render={ ({ isSubmitting, currentValues }) =>
                <Avatar
                  onChange={ this.handleAvatarSubmit }
                  defaultValues={ user }
                  currentValues={ currentValues }
                  disabled={ !isEditable || isSubmitting }
                />
              }
            />
            <Form
              onChange={ this.handleChange }
              onSubmit={ this.handleSubmit }
              render={ ({ isSubmitting, isValid, values: currentValues }) => (
                <>
                  <Tabs
                    items={{
                      [i18n._(t`Tu cuenta`)]: (
                        <General
                          defaultValues={ user }
                          currentValues={ currentValues }
                          disabled={ !isEditable }
                        />
                      ),
                      [i18n._(t`Otra información`)]: (
                        <UserTypeSpecific
                          type={ user.type }
                          defaultValues={ user }
                          currentValues={ currentValues }
                          disabled={ !isEditable }
                        />
                      ),
                    }}
                  />
                  {
                    !isEditable ?
                      <Actions>
                        <Button
                          onClick={ () => this.setEditable(true) }
                          isPrimary
                        >
                          <Trans>Editar</Trans>
                        </Button>
                      </Actions> :
                      <Submit
                        isDisabled={ isSubmitting || !isValid }
                        actionLabel={ i18n._(t`Guardar`) }>
                        <Button
                          onClick={ () => this.setEditable(false) }
                          isSecondaryDark
                        >
                          <Trans>Cancelar</Trans>
                        </Button>
                      </Submit>
                  }
                </>
              )}
            />
          </>
          :
          <Loading isFullScreen />
        }
      </Main>
    )
  }
}

export default flowRight(
  withAuth,
  withLocale
)(Profile)
